import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["container", "form"]

    static values = {
        show: {type: Boolean, default: false},
        conditions: {type: Array, default: []},
    }

    connect() {
    }

    toggle(event) {
        const formData = new FormData(this.formTarget)
        this.showValue = !!this.conditionsValue.every(condition => {
            console.log(formData.get(condition.name))
            console.log(condition.name, condition.value)
            return formData.get(condition.name) === condition.value
        });
    }

    showValueChanged() {
        this.containerTarget.hidden = !this.showValue
    }
}